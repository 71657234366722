import React from "react"

import YAMLdata from "./consulting-front.yaml"

const ConsultingFront = () => {
  return (
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>{YAMLdata.title}</h2>
          <p>{YAMLdata.content}</p>
        </div>
        <div className="main_title">
          <p>Benefits of our Managed Solution as a service (MSaaS)</p>
        </div>
        <div className="feature_inner row">
          <div className="col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-multi"></i>
              <h4>Multi Vendor enabled</h4>
              <p>
                Ngnapps offers products that are multi-vendor enabled and
                support easy integration with any 3gpp compliant platforms.
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-budget"></i>
              <h4>Reduces the upfront or the hardware cost</h4>
              <p>
                Deploy a single network component or an entire LTE Private
                network from our extensive product portfolio of Ngnapps.
                Alternatively start with a single product and incrementally add
                new products over time as your business plan dictates and
                helping operators to reduce upfront cost or the hardware cost.
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-replacement"></i>
              <h4>
                Quickly and cost effectively replaces the end of life equipments
              </h4>
              <p>
                Ngnapps’ Platform as a Service helps in speedy implementations
                of LTE and 5G network elements and fast integration with
                existing Network, which reduces the teething issues of
                coordination and timing/availability from different parties and
                also helps in cost-effective replacement for the end of life
                equipment’s.
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-yes"></i>
              <h4>
                Provides personal testing capabilities eliminating dependencies
              </h4>
              <p>
                NgNapps Lab eliminates dependencies for IoT device manufacturers
                on cellular operators as they can do end to end testing in lab
                environment Lab could be used to certify modules and help
                customers choose the appropriate modules from their
                manufacturers. Certifying them for aggressiveness, continuity,
                quality and standard compliances.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ConsultingFront
