import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ConsultingFront from "../sections/consulting-front"
import HomeSolutions from "../sections/home-solutions"

const ConsultingHome = () => (
  <Layout>
    <SEO title="Consulting" />
    {/* <!--================Common Banner Area =================--> */}
    <section className="banner_area banner_area_02">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>Consulting</h2>
            <div className="page_link">
              <Link to="/">Home</Link>
              <Link to="#">Consulting</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Common Banner Area =================--> */}
    <ConsultingFront />
    <HomeSolutions />
  </Layout>
)

export default ConsultingHome
